<template>
    <Header :title="name" :backLinkText="$tc('back_to_company', 2)" :backLinkURL="{ name: 'companies' }" />

    <section class="mt-10">
        <TabMenu :links="menuLinks" />
    </section>

    <section class="mt-10">
        <router-view />
    </section>
</template>

<script>
    import TabMenu from '@/components/general/TabMenu';

    export default {
        data() {
            return {
                menuLinks: [
                    { to: { name: 'companyOverview' }, display_name: this.$tc('overview', 1) },
                    { to: { name: 'companyUsers' }, display_name: this.$tc('user', 2) },
                ],
            };
        },
        components: {
            TabMenu,
        },
        created() {
            this.$store.dispatch('companies/fetchCompany', this.$route.params.company_id);
        },
        computed: {
            name() {
                return this.$store.getters['companies/name'];
            },
        },
    };
</script>
