<template>
    <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8">
            <router-link v-for="link in links" :key="link" v-slot="{ isActive }" :to="link.to">
                <span :class="[isActive ? 'border-indigo-500 text-indigo-600' : 'border-transparant text-gray-500']" class="block pb-3 border-transparant border-b text-base">{{ link.display_name }}</span>
            </router-link>
        </nav>
    </div>
</template>

<script>
    export default {
        props: {
            links: { type: Array, required: true, default: () => [] },
        },
    };
</script>
